<template>
  <ui-component-modal
    modalTitle=""
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteRequest"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="delete-content-container">
        <div class="mb-4 is-size-5 has-text-centered">
          Are you sure you want to delete the request?
        </div>

        <div class="has-text-centered">
          <Checkbox
            v-model="isDeleteConfirmed"
            :label="$t('General.Text_YesSure')"
            @click.ctrl.left.exact="deleteLink"
            class="is-inline-block"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import channelProvider from '@/providers/channel'

export default {
  components: {
    Checkbox: () => import('@/components/UI/Form/Checkbox.vue'),
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    request: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {},

  methods: {
    deleteRequest() {
      if (!this.isDeleting) {
        this.isDeleting = true

        channelProvider.methods
          .deleteChannelArticlePublishRequest(this.channel.Id, this.request.Id)
          .then((response) => {
            if (response.status === 204) {
              this.$emit('requestDeleted', this.request)

              this.onClickCancel()
            }
          })
          .catch((err) => {})
          .finally(() => {
            this.isDeleting = false
          })
      }
    },
  },
}
</script>
